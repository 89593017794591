import React, { Component } from "react";

class Contact extends Component{

    render(){
        return(
            <React.Fragment>
                {/* <PageHelmet pageTitle='Contact' /> */}

                 {/* Start Breadcrump Area */}
                 <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--36">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">Evolve Your<br/>Guest Experience<br/>Today</h2>
                                    <p>Join the AI revolution and take your guest communications to the next level.</p>
                                    <div className="rn-form-group rn-form-cta-block pt-10">
                                        {/* <button className="rn-button-style--2 btn-solid" type="submit" value="submit" name="submit" id="mc-embedded-subscribe">Get in touch</button> */}
                                        <button className="rn-button-style--2 btn-solid mt--25"><a href="#contact" style={{color: 'white'}}>Get Started Now</a></button>
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}
                
            </React.Fragment>
        )
    }
}
export default Contact