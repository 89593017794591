import React, { Component } from "react";
import Scrollspy from 'react-scrollspy'

class HeaderApartments extends Component{
    constructor(props) {
        super(props);
        this.stickyHeader = this.stickyHeader.bind(this);

        window.addEventListener('load', function() {
            console.log('All assets are loaded');
        })
        
    }

    stickyHeader () {}

    render(){

        window.addEventListener('scroll', function() {
            var value = window.scrollY;
            if (value > 100) {
                document.querySelector('.header--fixed').classList.add('sticky')
            }else{
                document.querySelector('.header--fixed').classList.remove('sticky')
            }
        });

        const { logo, color='default-color' } = this.props;
        let logoUrl;
        if(logo === 'light'){
            logoUrl = <a href={`${process.env.PUBLIC_URL}/`}><img src="/assets/images/logo/logo-light.png" alt="Matteo Giuzzi logo" /></a>;
        }else if(logo === 'dark'){
            logoUrl = <a href={`${process.env.PUBLIC_URL}/`}><img src="/assets/images/logo/logo-dark.png" alt="Matteo Giuzzi logo" /></a>;
        }else if(logo === 'symbol-dark'){
            logoUrl = <a href={`${process.env.PUBLIC_URL}/`}><img src="/assets/images/logo/logo-symbol-dark.png" alt="Matteo Giuzzi logo" /></a>;
        }else if(logo === 'symbol-light'){
            logoUrl = <a href={`${process.env.PUBLIC_URL}/`}><img src="/assets/images/logo/logo-symbol-light.png" alt="Matteo Giuzzi logo" /></a>;
        }else{
            logoUrl = <a href={`${process.env.PUBLIC_URL}/`}><img src="/assets/images/logo/e2ai-logotype.png" alt="E2AI logo" width="250"/></a>;
        }
        
        return(
            <header className={`header-area header-style-two header--fixed ${color}`}>
                <div className="header-wrapper" id="header-wrapper">
                    <div className="header-left d-flex align-items-center">
                        <div className="logo">
                            <a href={this.props.homeLink}>
                                {logoUrl}
                            </a>
                        </div>
                    </div>
                    <div className="header-right">
                    <nav className="mainmenunav d-lg-block ml--50 mr--30">
                        <Scrollspy className="mainmenu" items={['features', 'questions']} currentClassName="is-current" offset={-200}>
                        <li><a href="#features">Features</a></li>
                        <li><a href="#questions">Questions</a></li>
                        </Scrollspy>
                    </nav>
                    <div className="header-btn">
                        <a className="rn-btn" href="#contact">
                            <span>Request a demo</span>
                        </a>
                    </div>
                    </div>
                </div>
            </header>
        )
    }
}
export default HeaderApartments;