import React, { Component } from "react";
import { Background } from "react-parallax";

class LifeCoachContact extends Component{
    constructor(props){
        super(props);
        this.state = {
            rnName: '',
            rnEmail: '',
            rnSubject: '',
            rnMessage: '',
        };
    }
    render(){
        return(
            <div className="contact-form--1 e2ai-contact-form">
                <div className="container">
                {/* <h2 className="title text-center contact-form-title contact-form-subtitle pb--50">Get in touch and let's chat!</h2> */}
                    <div className="row row--35 align-items-center">
                        <div className="col-12">
                            <div className="form-wrapper transparent-card-contact">
                                <div class="section-title text-center mb--20">
                                    <h4 className="title" style={{fontSize:"18px"}}>Start Your Custom AI Solution Development Today!</h4>
                                    {/* <p className="description">You can also send an email directly at:<a href="mailto:info@evolvetoai.com"> info@evolvetoai.com</a> or through the form below.</p> */}
                                </div>
                                {/* <form action="https://app.99inbound.com/api/e/VUxE-hCU" method="POST" target="_blank"> */}
                                <form action="https://formkeep.com/f/72cf94c6607c"
                                    accept-charset="UTF-8"
                                    enctype="multipart/form-data"
                                    method="POST">
                                    <label htmlFor="item01">
                                        <input
                                            type="text"
                                            name="name"
                                            id="item01"
                                            value={this.state.rnName}
                                            onChange={(e)=>{this.setState({rnName: e.target.value});}}
                                            placeholder="Your Name *"
                                        />
                                    </label>

                                    <label htmlFor="item02">
                                        <input
                                            type="text"
                                            name="email"
                                            id="item02"
                                            value={this.state.rnEmail}
                                            onChange={(e)=>{this.setState({rnEmail: e.target.value});}}
                                            placeholder="Your Email *"
                                        />
                                    </label>

                                    {/* <label htmlFor="item03">
                                        <input
                                            type="text"
                                            name="subject"
                                            id="item03"
                                            value={this.state.rnSubject}
                                            onChange={(e)=>{this.setState({rnSubject: e.target.value});}}
                                            placeholder="Write a Subject"
                                        />
                                    </label> */}
                                    <label htmlFor="item03">
                                        <textarea
                                            type="text"
                                            id="item04"
                                            name="message"
                                            value={this.state.rnMessage}
                                            onChange={(e)=>{this.setState({rnMessage: e.target.value});}}
                                            placeholder="Describe your idea or project"
                                            style={{lineHeight:"initial", paddingTop:"15px"}}
                                        />
                                    </label>
                                    <button className="contact-form-btn rn-button-style--2 submit-btn btn-solid e2ai-btn" type="submit" value="submit" name="submit" id="mc-embedded-subscribe">Evolve to AI</button>
                                    {/* <button class="contact-form-btn rn-button-style--2 submit-btn" type="submit" value="submit" name="submit" id="mc-embedded-subscribe">Submit</button> */}
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default LifeCoachContact;