import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { FiCheck, FiInfo } from "react-icons/fi";

class TabsOne extends Component{
    render(){
        let 
        tab1 = "Basics", 
        tab2 = "Tech Support",
        tab3 = "Local Tips";
        const { tabStyle } = this.props;
        var checklistItemOne = [
            '<strong>Reservation Details</strong>: Quick access to reservation specifics such as duration of stay, number of guests, and any special requirements or notes made during the booking.',
            "<strong>House Rules Quick Glance</strong>: A quick reference for the property's rules and guidelines, ensuring guests respect the space.",
            '<strong>Wi-Fi Access</strong>: Display and easy retrieval of Wi-Fi access codes',
            '<strong>FAQ Knowledge Base</strong>: The chatbot can have a repository of frequently asked questions for guests to search through for quick answers.'
        ];

        var checklistItemTwo = [
            '<strong>Appliance User Manuals</strong>: A library of digital user manuals for all major appliances in the property.',
            '<strong>Appliance Repair Assistant</strong>: Offers guidance on basic fixes for standard household appliances and when to seek professional help.',
            '<strong>Report Damage Feature</strong>: Allows guests to quickly inform owners about any damaged items or areas in the property.',
            '<strong>Emergency Support</strong>: Directs guests on immediate steps to take for more serious problems, like water leaks or electrical issues.'
        ];

        var checklistItemThree = [
            "<strong>Local Amenities</strong>: Knowledge of nearby attractions, essentials like grocery stores or pharmacies, and recommendations based on previous guests' favorites.",
            "<strong>Event Calendar</strong>: Updated information on local events, festivals, or other activities happening around the area during the guest's stay.",
            '<strong>Local Deals and Discounts</strong>: Collaborate with local businesses to offer exclusive deals or discounts to guests, enhancing their stay and supporting the community.',
            '<strong>Transportation Tips</strong>: Information on nearby public transport, reputable taxi services, or even bike rental locations.'
        ];
       
        return(
            <div>
                {/* Start Tabs Area */}
                <div className="tabs-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <p className="text-center" style={{fontSize: "14px", marginTop:"-22px", marginBottom:"40px"}}><FiInfo /> Explore each of the categories below to learn more</p>
                                <Tabs>
                                    <TabList  className={`${tabStyle}`}>
                                        <Tab>{tab1}</Tab>
                                        <Tab>{tab2}</Tab>
                                        <Tab>{tab3}</Tab>
                                    </TabList>

                                    <TabPanel>
                                       <div className="single-tab-content">
                                            <p className="text-center">Inquiries about fundamental information, such as Wi-Fi access codes, air conditioning guidelines, and check-in/check-out schedules.</p>
                                            <div className="row mt--30" style={{alignItems:"center", justifyContent:"center"}}> 
                                                <div className="col-lg-3 text-center phone-image">
                                                        <img src="/assets/images/features/wa-basics.png" width="300"/>
                                                </div>
                                                <div className="col-lg-6">
                                                    {/* <h4>Lorem ipsum dolor sit.</h4> */}
                                                    <ul className="list-style--1">
                                                        {checklistItemOne.map((item, index) => {
                                                            return <li key={index}><FiCheck /> <span dangerouslySetInnerHTML={{ __html: item }} /></li> 
                                                        })}
                                                    </ul>
                                                </div>
                                            </div>
                                       </div>
                                    </TabPanel>

                                    <TabPanel>
                                    <div className="single-tab-content">
                                            <p className="text-center">Intelligent support for technical difficulties, such as malfunctioning devices, damaged appliances, and plumbing concerns.</p>
                                            <div className="row mt--30" style={{alignItems:"center", justifyContent:"center"}}>  
                                                <div className="col-lg-3 text-center phone-image">
                                                        <img src="/assets/images/features/wa-techsupport.png" width="300"/>
                                                </div>
                                                <div className="col-lg-6">
                                                    {/* <h4>Lorem ipsum dolor sit.</h4> */}
                                                    <ul className="list-style--1">
                                                        {checklistItemTwo.map((item, index) => {
                                                            return <li key={index}><FiCheck /> <span dangerouslySetInnerHTML={{ __html: item }} /></li> 
                                                        })}
                                                    </ul>
                                                </div>
                                            </div>
                                       </div>
                                    </TabPanel>

                                    <TabPanel>
                                    <div className="single-tab-content">
                                            <p className="text-center">Updated information on local events, festivals, or other activities happening around the area during the guest's stay.</p>
                                            <div className="row mt--30" style={{alignItems:"center", justifyContent:"center"}}>  
                                                <div className="col-lg-3 text-center phone-image">
                                                        <img src="/assets/images/features/wa-localtips.png" width="300"/>
                                                </div>
                                                <div className="col-lg-6">
                                                    {/* <h4>Lorem ipsum dolor sit.</h4> */}
                                                    <ul className="list-style--1">
                                                        {checklistItemThree.map((item, index) => {
                                                            return <li key={index}><FiCheck /> <span dangerouslySetInnerHTML={{ __html: item }} /></li> 
                                                        })}
                                                    </ul>
                                                </div>
                                            </div>
                                       </div>
                                    </TabPanel>
                                    
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Tabs Area */}
            </div>
        )
    }
}



export default TabsOne;