import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import "../../public/assets/scss/template/_predictions.scss";
// import axios from "axios";

const ChatAIPredictions = () => {
//   const [message, setMessage] = useState("");
//   const [email, setEmail] = useState("");

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     alert(
//       "Grazie per esserti aggiunto alla coda! Se il tuo nome utente fa parte del nostro gruppo Telegram, ci metteremo in contatto con te al più presto."
//     );

//     try {
//       const response = await axios.post(
//         "https://script.google.com/macros/s/AKfycbwyAMdlfZFBfUHb3ux9U2suz8bUTNLVpQxV_1tZBdKnNRdFsvEuWUAJjvwMI7U4wd--yg/exec",
//         { email }
//       );
//       setMessage(response.data.message || "Submission successful!");
//       setEmail("");
//     } catch (error) {
//       console.error(error);
//       setMessage("Error submitting form.");
//     }
//   };

  return (
    // <div className="chat-ai-predictions">
    <div className="active-light chat-ai-predictions">
      {/* Add Google Fonts dynamically */}
      <Helmet>
        <link
          href="https://fonts.googleapis.com/css2?family=Jockey+One&family=Oswald:wght@200..700&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      {/* Navbar */}
      <nav className="navbar">
        <div className="navbar-group">
          <a href="/" className="navbar-logo">
            <img
              src="/assets/images/logo/pronostici-ai-logo.png"
              alt="Pronostici Calcio AI Logo"
            />
          </a>
          <h2 className="navbar-text jockey-one-regular">Pronostici Calcio AI</h2>
        </div>
        <a
          className="navbar-btn jockey-one-regular"
          href="https://t.me/pronosticicalcio_ai"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="/assets/images/logo/telegram-icon.png"
            alt="Telegram Logo"
            height="15"
            style={{ marginRight: "10px" }}
          />
          Canale Telegram
        </a>
      </nav>

      {/* Main Wrapper */}
      <div className="wrapper">
        <h3 className="jockey-one-regular">
          Inizia ad interagire con la nostra IA e richiedi qualsiasi pronostico su <strong style={{color: "#FFD521"}}>Serie A, Serie B e La Liga</strong>. 
        </h3>
        <p className="oswald-regular">
          Questa chat è tua <strong>personale</strong> e nessun altro utente può accedervi.<br></br>
          Hai accesso a 2 pronostici gratuiti.
        </p>
        <p className="oswald-regular">Per ricevere accesso a pronostici illimitati con un account Premium
            <a
            className="predictions-btn"
            href="https://buy.stripe.com/fZe6pV67s3N1czS8wx"
            target="_blank"
            rel="noopener noreferrer"
            style={{display: "block", maxWidth: "160px", fontWeight: "bold"}}
            >
            ISCRIVITI QUI
            </a>
            <img
                src="/assets/images/logo/powered_by_stripe_logo.png"
                alt="Stripe Logo"
                height="40"
                style={{ display: "block", margin: "0 auto" }}
            />
            <div style={{maxWidth: "80%", margin: "0 auto"}}>
                <strong className="oswald-regular" style={{fontSize: "0.9em", fontStyle: "italic"}}>(Il pagamento è assicurato e protetto da Stripe e può essere effettuato con qualsiasi carta di credito, bancomat o PostePay)<br></br>Se  invece sei già un utente abbonato al servizio, ricordati di specificare nella chat qui sotto il nome utente e la password che ti abbiamo fornito su Telegram prima di richiedere dei pronostici.</strong>
            </div>
        </p>
        
        <p></p>
        <p className="oswald-regular">
          
        </p>

        {/* Chatbot Embed */}
        <iframe
          src="https://udify.app/chatbot/VS9faPGQ8w8165Cv"
          style={{ width: "100%", height: "700px" }}
          frameBorder="0"
          allow="microphone"
          title="Pronostici Calcio AI"
        ></iframe>

        {/* Form */}
        {/* <form id="lead-form" onSubmit={handleSubmit}>
          <label htmlFor="email">Inserisci la tua email:</label>
          <input
            type="text"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="example@email.com"
            required
          />
          <button type="submit">Iscriviti</button>
        </form>
        {message && <div id="message">{message}</div>} */}
      </div>

      {/* Footer */}
      <div className="footer oswald-regular">
        Copyright © 2023{" "}
        <a
          href="https://evolvetoai.com"
          target="_blank"
          rel="noopener noreferrer"
          style={{color: "#FFD521"}}
        >
          Evolve2AI
        </a>
        . All Rights Reserved.
      </div>
    </div>
  );
};

export default ChatAIPredictions;