import React, { Component , Fragment } from "react";
import Slider from "react-slick";
import {Link} from "react-router-dom";
import { slickDot } from "../page-demo/script";
import Scrollspy from 'react-scrollspy';
import ScrollToTop from 'react-scroll-up';
import Particles from "react-tsparticles";
import { FiChevronUp , FiX , FiMenu, FiCrosshair, FiFrown } from "react-icons/fi";
import ServiceList from "../elements/service/ServiceList";
import CounterOne from "../elements/counters/CounterOne";
import Testimonial from "../elements/Testimonial";
import Team from "../elements/Team";
import BlogContent from "../elements/blog/BlogContent";
import BrandTwo from "../elements/BrandTwo";
import FooterTwo from "../component/footer/FooterTwo";
import Contact from "../elements/contact/ContactTwo";
import About from "../elements/About";
import TabFeatures from "../elements/tab/TabFeatures";
import Helmet from "../component/common/Helmet";

import CTABlock from "../elements/CTAblock/CTAblock";
import ContactApartment from "../elements/contact/ContactApartment";

import { FiCheck } from "react-icons/fi";

import HeaderApartments from "../component/header/HeaderApartments";

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton,
} from 'react-accessible-accordion';

const features = [
    {name: 'Feature 1', type1: 'Value A', type2: 'Value B'},
    {name: 'Feature 2', type1: 'Value C', type2: 'Value D'},
    // ... add more features as needed
];

const SlideList = [
    {
        textPosition: 'text-center',
        category: '',
        title: 'Revolutionize Your Guest Experience with Advanced AI',
        description: 'Seamless communication for holiday apartment hosts, powered by state-of-the-art AI bots.',
        buttonText: 'Request A Demo',
        buttonLink: '#contact'
    }
]

const list = [
    {
        image: 'image-1',
        category: 'Development',
        title: 'Getting tickets to the big show'
    },
    {
        image: 'image-2',
        category: 'Development',
        title: 'Getting tickets to the big show'
    },
    {
        image: 'image-3',
        category: 'Development',
        title: 'Getting tickets to the big show'
    },
    {
        image: 'image-4',
        category: 'Development',
        title: 'Getting tickets to the big show'
    },
    {
        image: 'image-3',
        category: 'Development',
        title: 'Getting tickets to the big show'
    },
    {
        image: 'image-4',
        category: 'Development',
        title: 'Getting tickets to the big show'
    }
]

class HomeParticles extends Component{
    constructor(props) {
        super(props);
        this.menuTrigger = this.menuTrigger.bind(this);
        this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
        this.stickyHeader = this.stickyHeader.bind(this);

       //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
        window.addEventListener('load', function() {
            console.log('All assets are loaded');
        })
    }
    menuTrigger() {
        document.querySelector('.header-wrapper').classList.toggle('menu-open')
    }
    CLoseMenuTrigger() {
        document.querySelector('.header-wrapper').classList.remove('menu-open')
    }
    stickyHeader () {}
    render(){
        const PostList = BlogContent.slice(0 , 5);
        
        window.addEventListener('scroll', function() {
            var value = window.scrollY;
            if (value > 100) {
                document.querySelector('.header--fixed').classList.add('sticky')
            }else{
                document.querySelector('.header--fixed').classList.remove('sticky')
            }
        });

        var elements = document.querySelectorAll('.has-droupdown > a');
        for(var i in elements) {
            if(elements.hasOwnProperty(i)) {
                elements[i].onclick = function() {
                    this.parentElement.querySelector('.submenu').classList.toggle("active");
                    this.classList.toggle("open");
                }
            }
        }
        
        return(
            <Fragment>
                <div className="active-e2ai-apartment">
                    <Helmet pageTitle="Evolve2AI - Chat GPT for Airbnb Owners" />

                    {/* Start Header Area  */}
                    <HeaderApartments />
                    {/* End Header Area  */}

                    {/* Start Slider Area   */}
                    
                    <div className="slider-activation slider-creative-agency with-particles" id="home">
                        <div className="frame-layout__particles" style={{backdropFilter:"blur(5px)"}}>
                            <Particles className="particle"  
                                options={{
                                    style:{
                                        position: "absolute"
                                    },
                                    fpsLimit: 100,
                                    interactivity: {
                                    detectsOn: "canvas",
                                    events: {
                                        onClick: {
                                        enable: true,
                                        mode: "push",
                                        },
                                        onHover: {
                                        enable: true,
                                        mode: "repulse",
                                        },
                                        resize: true,
                                    },
                                    modes: {
                                        bubble: {
                                            distance: 100,
                                            duration: 2,
                                            opacity: 0.8,
                                            size: 10,
                                            color: "#888",
                                        },
                                        push: {
                                        quantity: 4,
                                        },
                                        repulse: {
                                            distance: 100,
                                            duration: 0.4,
                                            color: "#888",
                                        },
                                    },
                                    },
                                    particles: {
                                    color: {
                                        value: "#888",
                                    },
                                    links: {
                                        color: "#888",
                                        distance: 150,
                                        enable: true,
                                        opacity: 0.5,
                                        width: 1,
                                    },
                                    collisions: {
                                        enable: true,
                                    },
                                    move: {
                                        direction: "none",
                                        enable: true,
                                        outMode: "bounce",
                                        random: false,
                                        speed: 6,
                                        straight: false,
                                    
                                    },
                                    number: {
                                        density: {
                                            enable: true,
                                            value_area: 2000,
                                        },
                                        value: 80,
                                    },
                                    opacity: {
                                        value: 0.5,
                                    },
                                    shape: {
                                        type: "circle",
                                    },
                                    size: {
                                        random: true,
                                        value: 5,
                                    },
                                    },
                                    detectRetina: true,
                                }}
                            />
                        </div>
                        <div className="bg_image bg_image--41">
                                {SlideList.map((value , index) => (
                                    <div className="slide slide-style-2 slider-paralax d-flex align-items-center justify-content-center" key={index}>
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className={`inner ${value.textPosition}`}>
                                                        {/* <div><h1 className="align-items-center justify-content-center mb--0" style={{display:"inline-block", color:"white",}}>EVOLVE2AI</h1> <img className="mb--35" src="/assets/images/logo/poweredby-gpt4.png" width="300"/></div> */}
                                                        <div className="pb--30">
                                                        <h1 className="title e2ai-title" style={{}}>Evolve2AI</h1>
                                                        <img src="/assets/images/logo/poweredby-gpt4.png" width="250"/>
                                                        </div>                                                    
                                                        {value.category ? <span>{value.category}</span> : ''}
                                                        {value.title ? <h1 className="title theme-gradient" style={{ fontSize: '80px' }}>{value.title}</h1> : ''}
                                                        {value.description ? <p className="description">{value.description}</p> : ''}
                                                        {value.buttonText ? <div className="slide-btn"><a className="rn-button-style--2 btn-primary-color" href={`${value.buttonLink}`}>{value.buttonText}</a></div> : ''}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                    </div>
                    {/* End Slider Area   */}
                    
                {/* Start About Area */}
                <div className="about-area ptb--120  bg_color--1">
                        <div className="about-wrapper">
                            <div className="container">
                                <div className="row row--35">
                                    <div className="col-lg-12">
                                        <div className="about-inner inner">
                                            <div className="section-title">
                                                {/* <h2 className="title">Title</h2> */}
                                                <p className="description">At Evolve2AI, we are passionate about revolutionizing property management through the power of artificial intelligence. Our mission is to facilitate the lives of property owners by providing innovative AI solutions that streamline communication, enhance guest experiences, and simplify property management tasks.</p>
                                            </div>
                                            {/* <div className="row mt--30">
                                                <TabOne tabStyle="tab-style--1" />
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* End About Area */}

                    {/* Start Common Problems Area */}
                    <div className="features-area pt--120 bg_color--5" id="features">
                        <div className="afeatures-wrapper">
                            <div className="container">
                                <div className="row row--35">
                                    <div className="col-lg-12">
                                        <div className="features-inner inner">
                                            <div className="section-title text-center">
                                                <span className="sub-title">Evolve2AI features</span>
                                                <h2 className="title">Simple yet powerful features</h2>
                                            </div>
                                            <div className="row mt--30">
                                                <TabFeatures tabStyle="tab-style--1 tabs-center" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* End Common Problems Area */}


                    {/* Start Platforms Area */}
                    <div className="platforms-area text-center ptb--50">
                        <h3>Available on</h3>
                        <img src="/assets/images/features/aibot-platforms.png"/>
                    </div>
                    {/* End Platforms Area */}

                    {/* Start Service Area  */}
                    <div className="service-area creative-service-wrapper ptb--120 bg_color--1" id="service">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <div className="section-title text-center mb--30">
                                        <h2>Why Choose Our AI Solutions?</h2>
                                        {/* <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration.</p> */}
                                    </div>
                                </div>
                            </div>
                            <div className="row creative-service">
                                <div className="col-lg-12">
                                    <ServiceList item="6" column="col-lg-4 col-md-6 col-sm-6 col-12 text-left" />
                                </div>
                            </div>
                        </div>
                    </div>  
                    {/* End Service Area  */} 

                    {/* Start Difference Area */}
                        <div className="difference-area row text-center ptb--50" style={{background: "#232323"}}>
                            <div className="col-lg-12">
                                <h3 className="pb--25" style={{color:"white"}}>Do you already have a regular chatbot?</h3>
                                <img className="bot-differences" src="/assets/images/features/botdifference.png"/>
                                <img className="bot-differences mobile" src="/assets/images/features/botdifference-mobile.png"/>
                            </div>
                        </div>
                    {/* End Difference Area */}

                    {/* Start Pricing Table Area  */}
                    <div className="rn-pricing-table-area ptb--120 bg_color--5">
                        <div className="container">
                            <div class="section-title text-center mb--30">
                                <h2>Our offer</h2>
                            </div>
                            <div className="row justify-content-center">
                                {/* Start Pricing Table Area  */}
                                <div className="col-lg-4 col-md-6 col-12"> 
                                    <div className="rn-pricing">
                                        <div className="pricing-table-inner">
                                            <div className="pricing-header">
                                                <h4 className="title">Basic</h4>
                                                {/* <div className="pricing">
                                                    <span className="price">29</span>
                                                    <span className="subtitle">USD Per Month</span>
                                                </div> */}
                                            </div>
                                            <div className="pricing-body">
                                                <ul className="list-style--1">
                                                    <li><FiCheck /> Thought for individual hosts</li>
                                                    <li><FiCheck /> Standard AI responses</li>
                                                    <li><FiCheck /> 24/7 bot availability</li>
                                                    <li><FiCheck /> Multilingual support</li>
                                                    <li style={{ opacity:'0.5'}}><FiFrown /> Guest Insights & data analysis</li>
                                                    <li style={{ opacity:'0.5'}}><FiFrown /> Priority support</li>
                                                </ul>
                                            </div>
                                            <div className="pricing-footer">
                                                <a className="rn-btn" href="#contact">Request a Call</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* End Pricing Table Area  */}

                                {/* Start Pricing Table Area  */}
                                <div className="col-lg-4 col-md-6 col-12">
                                    <div className="rn-pricing active">
                                        <div className="pricing-table-inner">
                                            <div className="pricing-header">
                                                <h4 className="title">Business</h4>
                                                {/* <div className="pricing">
                                                    <span className="price">29</span>
                                                    <span className="subtitle">USD Per Month</span>
                                                </div> */}
                                            </div>
                                            <div className="pricing-body">
                                                <ul className="list-style--1">
                                                    <li><FiCheck /> Thought for professional hosts</li>
                                                    <li><FiCheck /> Customization and advanced AI responses</li>
                                                    <li><FiCheck /> 24/7 bot availability</li>
                                                    <li><FiCheck /> Multilingual support</li>
                                                    <li><FiCheck /> Guest Insights & data analysis</li>
                                                    <li><FiCheck /> Priority support</li>
                                                </ul>
                                            </div>
                                            <div className="pricing-footer">
                                                <a className="rn-btn" href="#contact">Request a Call</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* End Pricing Table Area  */}
                                
                            </div>
                        </div>
                    </div>
                    {/* End Pricing Tbale Area  */}

                    {/* Start Faq Area */}
                    <div className="rn-accordion-area pv-feaq-area bg_color--1 ptb--120" id="questions">
                        <div className="container">
                            <div className="row row--35">
                                <div className="col-lg-8 col-sm-12">
                                    <div className="section-title text-left mb--30">
                                        <h2 className="title">Got <span className="theme-color">Questions</span>?<br/>
                                        We've Got Answers.
                                        </h2>
                                        {/* <p>Check out our FAQ section to see if we can help.</p> */}
                                    </div>
                                    <div className="faq-area">
                                        <Accordion className="accodion-style--1" preExpanded={'0'}>
                                            <AccordionItem >
                                                <AccordionItemHeading>
                                                    <AccordionItemButton>
                                                        1. What kind of inquiries can the bot handle on my behalf?
                                                    </AccordionItemButton>
                                                </AccordionItemHeading>
                                                <AccordionItemPanel>
                                                    <p>The bot can handle a range of inquiries including reservation details, house rules, local recommendations, technical support for household appliances, and more. It's designed to provide immediate responses to common questions, enhancing guest satisfaction.</p>
                                                </AccordionItemPanel>
                                            </AccordionItem>

                                            <AccordionItem >
                                                <AccordionItemHeading>
                                                    <AccordionItemButton>
                                                        2. How does the bot deal with questions it cannot answer?
                                                    </AccordionItemButton>
                                                </AccordionItemHeading>
                                                <AccordionItemPanel>
                                                    <p>In case the bot encounters a question it cannot answer, it can either direct the guest to contact you directly or notify you about the inquiry through your preferred method, enabling timely and accurate responses.</p>
                                                </AccordionItemPanel>
                                            </AccordionItem>

                                            <AccordionItem >
                                                <AccordionItemHeading>
                                                    <AccordionItemButton>
                                                        3. Will I be able to customize the bot according to my property's specifics?
                                                    </AccordionItemButton>
                                                </AccordionItemHeading>
                                                <AccordionItemPanel>
                                                    <p>Absolutely! Our bots are highly customizable to match the unique offerings and rules of your property. During setup, we'll work closely with you to ensure the bot aligns with your branding and property details.</p>
                                                </AccordionItemPanel>
                                            </AccordionItem>

                                            <AccordionItem >
                                                <AccordionItemHeading>
                                                    <AccordionItemButton>
                                                        4. Is there a learning curve for my guests in interacting with the bot?
                                                    </AccordionItemButton>
                                                </AccordionItemHeading>
                                                <AccordionItemPanel>
                                                    <p>Our bots are designed to be intuitive and user-friendly, requiring no learning curve from your guests. They will interact with it as easily as they would with a human over text or chat. They will keep learning from your guests though!</p>
                                                </AccordionItemPanel>
                                            </AccordionItem>

                                            <AccordionItem >
                                                <AccordionItemHeading>
                                                    <AccordionItemButton>
                                                        5. How secure is the information shared through the bot?
                                                    </AccordionItemButton>
                                                </AccordionItemHeading>
                                                <AccordionItemPanel>
                                                    <p>We prioritize security and ensure that all data exchanged through the bot is encrypted and compliant with data protection laws. Your and your guests' privacy is of utmost importance to us.</p>
                                                </AccordionItemPanel>
                                            </AccordionItem>

                                            <AccordionItem >
                                                <AccordionItemHeading>
                                                    <AccordionItemButton>
                                                        6. How difficult is the bot integration process?
                                                    </AccordionItemButton>
                                                </AccordionItemHeading>
                                                <AccordionItemPanel>
                                                    <p>After we've built and trained your custom AI bot, it will take seconds for you to integrate it on your web page- it's as easy as copy/pasting a link. We also offer customisable bots for Whatsapp, Telegram, Instagram and many others messaging platforms.</p>
                                                </AccordionItemPanel>
                                            </AccordionItem>

                                            <AccordionItem>
                                                <AccordionItemHeading>
                                                    <AccordionItemButton>
                                                        7. Can I turn off the bot and handle queries manually?
                                                    </AccordionItemButton>
                                                </AccordionItemHeading>
                                                <AccordionItemPanel>
                                                    <p>Absolutely. You will be able to continue talking with customers manually, if you wish to do so.</p>
                                                </AccordionItemPanel>
                                            </AccordionItem>
                                        </Accordion>
                                    </div>
                                </div>

                                <div className="col-lg-4 col-sm-12 mt_md--40 mt_sm--40">

                                    <div className="card-box text-left mt--40 support">
                                        <div className="service">
                                            <div className="icon">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-headphones"><path d="M3 18v-6a9 9 0 0 1 18 0v6"></path><path d="M21 19a2 2 0 0 1-2 2h-1a2 2 0 0 1-2-2v-3a2 2 0 0 1 2-2h3zM3 19a2 2 0 0 0 2 2h1a2 2 0 0 0 2-2v-3a2 2 0 0 0-2-2H3z"></path></svg>
                                            </div>
                                            <div className="content">
                                                <h3 className="title">Dedicated Support</h3>
                                                <p>All E2AI clients get professional help and fast, human support included with all of our plans.</p>
                                                {/* <a target="_blank" href="https://support.rainbowit.net/support/login" className="rbt-button rn-button-style--2 rn-btn-small-2 btn-solid-border"><span className="button-text">Get Support</span></a> */}
                                                <ul className="liststyle">
                                                    <li><span>Support Time:</span> Monday – Friday</li>
                                                    <li><span>Response Time:</span> Maximum 10 hours</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>        
                    </div>
                    {/* End Faq Area */}

                    {/* Start Team Area */}
                    {/* <div className="team-area bg_color--1 ptb--120">
                        <div className="row">
                            <div className="col-lg-6">
                                <img src="/assets/images/team/artur.png"/>
                                <p>Artur Tarabanov</p>
                            </div>
                            <div className="col-lg-6">
                                <img src="/assets/images/team/artur.png"/>
                            </div>
                        </div>
                    </div> */}
                    {/* End Team Area */}

                    {/* Start CTA Area */}
                    <CTABlock />
                    {/* End CTA Area */}

                    {/* Start Team Area  */}
                    {/* <div className="rn-team-area ptb--120 bg_color--1" id="team">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="section-title service-style--3 text-left mb--25 mb_sm--0">
                                        <h2 className="title">Skilled Team</h2>
                                        <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <Team column="col-lg-4 col-md-6 col-sm-6 col-12" />
                            </div>
                        </div>
                    </div> */}
                    {/* End Team Area  */}

                    {/* Start Testimonial Area */}
                    {/* <div className="rn-testimonial-area bg_color--5 ptb--120" id="testimonial">
                        <div className="container">
                            <Testimonial />
                        </div>
                    </div> */}
                    {/* End Testimonial Area */}

                    {/* Start Contact Us */}
                    <div className="rn-contact-us ptb--120 bg_color--5" id="contact">
                        <ContactApartment />
                    </div>
                    {/* End Contact Us */}


                    {/* Start Brand Area */}
                    {/* <div className="rn-brand-area bg_color--1 ptb--120">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <BrandTwo />
                                </div>
                            </div>
                        </div>
                    </div> */}
                    {/* End Brand Area */}

                    {/* Start Footer Style  */}
                    <FooterTwo />
                    {/* End Footer Style  */}
                    
                    {/* Start Back To Top */}
                    <div className="backto-top">
                        <ScrollToTop showUnder={160}>
                            <FiChevronUp />
                        </ScrollToTop>
                    </div>
                    {/* End Back To Top */}
                </div>
            </Fragment>
        )
    }
}
export default HomeParticles;