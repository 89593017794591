import React, { Component , Fragment } from "react";
import ModalVideo from 'react-modal-video';
import ScrollToTop from 'react-scroll-up';
import Slider from "react-slick";
import { slideSlick } from "../page-demo/script";
import ServiceList from "../elements/service/ServiceList";
import BlogContent from "../elements/blog/BlogContent";
import BrandTwo from "../elements/BrandTwo";
import PortfolioList from "../elements/portfolio/PortfolioE2AI";
import HeaderEmpty from "../component/header/HeaderEmpty";
import FooterThree from "../component/footer/FooterThree";
import CallAction from "../elements/callaction/CallAction";
import Team from "../elements/Team";
import Accordion01 from "../elements/Accordion";
import { FiCheck, FiChevronUp } from "react-icons/fi";
import Helmet from "../component/common/Helmet";
import ContactTransparentCard from "../elements/contact/ContactTransparentCard";
import ContactMainGeneric from "../elements/contact/ContactMainGeneric";

const SlideList = [
    {
        textPosition: 'text-left',
        bgImage: 'bg_image--40',
        category: '',
        title: 'Evolve2AI',
        description: 'Evolve your business performance with advanced AI technology',
        buttonText: 'Tell Us Your Idea',
        buttonLink: '#contact'
    },
    // {
    //     textPosition: 'text-left',
    //     bgImage: 'bg_image--18',
    //     category: '',
    //     title: 'Development.',
    //     description: 'There are many variations of passages of Lorem Ipsum available but the majority have suffered alteration.',
    //     buttonText: 'Contact Us',
    //     buttonLink: '/contact'
    // },
    // {
    //     textPosition: 'text-left',
    //     bgImage: 'bg_image--19',
    //     category: '',
    //     title: 'Marketing.',
    //     description: 'There are many variations of passages of Lorem Ipsum available but the majority have suffered alteration.',
    //     buttonText: 'Contact Us',
    //     buttonLink: '/contact'
    // }
]

class MainGeneric extends Component{
    constructor (props) {
        super(props)
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this);
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        var namesItemOne = [
            'The Philosophy Of business analytics',
            'Fast-Track Your business',
            'Lies And Damn Lies About business',
            'The Ultimate Deal On business',
        ];
        var namesItemTwo = [
            'Proof That business Really Works',
            'Here Is What You Should Do For Your business',
            'The Hidden Mystery Behind business',
        ];
        
        const PostList = BlogContent.slice(0 , 3);

        return(
            <Fragment>
                <div className="active-dark active-e2ai-main">
                <Helmet pageTitle="Evolve2AI - AI Solutions & Bots" />
                <HeaderEmpty />
                {/* Start Slider Area   */}
                <div className="slider-wrapper">
                    <div className="slider-activation">
                        <Slider className="rn-slick-dot dot-light" {...slideSlick}>
                            {SlideList.map((value , index) => (
                                <div className={`main-generic slide slide-style-2 compact d-flex align-items-center justify-content-center bg_image ${value.bgImage} ptb--120`} key={index} data-black-overlay="8">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <div className={`col-lg-12 inner ${value.textPosition}`}>
                                                    {value.category ? <span>{value.category}</span> : ''}
                                                    <div className="pb--30">
                                                    {value.title ? <h1 className="title" style={{lineHeight:"70px"}}>{value.title}</h1> : ''}
                                                    <img src="/assets/images/logo/poweredby-gpt4.png" width="250"/>
                                                    </div>
                                                    {value.description ? <p className="description pr--0">{value.description}</p> : ''}
                                                    {/* {value.buttonText ? <div className="slide-btn mt--20"><a className="rn-button-style--2 btn-solid e2ai-btn" href={`${value.buttonLink}`}>{value.buttonText}</a></div> : ''} */}
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-lg-offset-3 align-items-center justify-content-center pt--25 transparent-form-area">
                                                 <ContactTransparentCard/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
                {/* End Slider Area   */}
                
                {/* Start Brand Area */}
                {/* <div className="rn-brand-area bg_color--5 ptb--60">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-12">
                                <BrandTwo />
                            </div>
                        </div>
                    </div>
                </div> */}
                {/* End Brand Area */}

                {/* Start Service Area  */}
                {/* <div className="service-area creative-service-wrapper ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title service-style--3 text-center mb--30 mb_sm--0">
                                    <h2 className="title">Our Service</h2>
                                    <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row creative-service">
                            <div className="col-lg-12">
                                <ServiceList item="6" column="col-lg-4 col-md-6 col-sm-6 col-12 text-left" />
                            </div>
                        </div>
                    </div>
                </div>   */}
                {/* End Service Area  */}

                {/* Start About Area  */}
                <div className="rn-about-area ptb--120 bg_color--8 text-center">
                    <div className="container">
                        <div className="row row--35 align-items-center">
                            {/* <div className="col-lg-6">
                                <div className="thumbnail">
                                    <img className="w-100" src="/assets/images/about/about-4.png" alt="About Images"/>
                                </div>
                            </div> */}
                            <div className="col-lg-12">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <h2 className="title">About Us</h2>
                                        <p style={{fontSize:"22px", lineHeight:"initial"}}>Evolve2AI goes beyond your typical AI Automation Agency (AAA). We stand as your data and AI allies, ready to help you craft easy-to-deploy AI solutions in sync with your ideas and ambitions. Our mission is to simplify your AI journey, ensuring your business not only keeps pace, but leads the way. Your innovation, powered by our expertise—let’s evolve together! Ready to take the next step?</p>
                                    </div>
                                    {/* <div className="accordion-wrapper mt--30">
                                        <Accordion01 />
                                    </div> */}
                                    {/* <div className="about-button mt--50">
                                        <a className="rn-button-style--2 btn-solid" href="/about">See how it works</a>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area  */}

                {/* Start Portfolio Area */}
                <div className="portfolio-area ptb--120 bg_color--8">
                    <div className="portfolio-sacousel-inner">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title text-center service-style--3 mb--30 mb_sm--0">
                                        <h2 className="title">Our Expertise Areas</h2>
                                        {/* <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration.</p> */}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <PortfolioList styevariation="text-left mt--40" column="col-lg-4 col-md-6 col-sm-6 col-12" item="6" />
                            </div>
                            {/* <div className="row">
                                <div className="col-lg-12">
                                    <div className="view-more-btn mt--60 text-center">
                                        <a className="rn-button-style--2 btn-solid" href="/portfolio"><span>View More Project</span></a>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
                {/* End Portfolio Area */}

                {/* Start Team Area  */}
                {/* <div className="rn-team-area ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title service-style--3 text-center mb--25 mb_sm--0">
                                    <h2 className="title">Skilled Team</h2>
                                    <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <Team column="col-lg-4 col-md-6 col-sm-6 col-12" />
                        </div>
                    </div>
                </div> */}
                {/* End Team Area  */}


                {/* Start Blog Area */}
                {/* <div className="rn-blog-area pt--120 pb--80 bg_color--5">
                    <div className="container">
                        <div className="row align-items-end">
                            <div className="col-lg-12">
                                <div className="section-title service-style--3 text-center">
                                    <h2 className="title">Latest News</h2>
                                    <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row mt--60">
                            {PostList.map((value , i ) => (
                                <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                    <div className="blog blog-style--1">
                                        <div className="thumbnail">
                                            <a href="/blog-details">
                                                <img className="w-100" src={`/assets/images/blog/blog-${value.images}.jpg`} alt="Blog Images"/>
                                            </a>
                                        </div>
                                        <div className="content">
                                            <p className="blogtype">{value.category}</p>
                                            <h4 className="title"><a href="/blog-details">{value.title}</a></h4>
                                            <div className="blog-btn">
                                                <a className="rn-btn text-white" href="/blog-details">Read More</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>    
                    </div>    
                </div> */}
                {/* End Blog Area */}

                {/* Start call To Action  */}
                {/* <CallAction /> */}
                {/* End call To Action  */}

                {/* Start main contact form */}
                <ContactMainGeneric />
                {/* End main contact form */}

                {/* Start Footer Style  */}
                <FooterThree />
                {/* End Footer Style  */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                </div>
            </Fragment>
        )
    }
}
export default MainGeneric;