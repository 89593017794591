import React ,{ Component }from "react";
import { FiMessageCircle , FiAward , FiEdit, FiZap, FiAirplay, FiClock, FiLock, FiUserX, FiUserPlus } from "react-icons/fi";

const ServiceList = [
    {
        icon: <FiZap />,
        title: 'Instant Communication',
        description: 'Never let your guests wait. Our AI bots respond in real-time, enhancing guest satisfaction.'
    },
    {
        icon: <FiMessageCircle />,
        title: 'Multilingual Support',
        description: 'Break language barriers. Communicate with guests from all over the world.'
    },
    {
        icon: <FiClock />,
        title: '24/7 Availability',
        description: "Day or night, our bots are here to support your guest's needs."
    },
    { 
        icon: <FiEdit />,
        title: 'Customized Experience',
        description: 'Personalize guest interactions based on their preferences and booking history.'
    },
    { 
        icon: <FiUserPlus />,
        title: 'Guest Insights & Learnings',
        description: 'Analyze guest queries for popular amenities and local attractions to enhance listing descriptions, price and guest satisfaction.'
    },
    { 
        icon: <FiLock />,
        title: 'Secure Information Handling',
        description: "Ensure only authorized access to your property's data shared with the AI bot."
    }
]


class ServiceThree extends Component{
    render(){
        const {column } = this.props;
        const ServiceContent = ServiceList.slice(0 , this.props.item);
        
        return(
            <React.Fragment>
                <div className="row">
                    {ServiceContent.map( (val , i) => (
                        <div className={`${column}`} key={i}>
                            <a href="#" style={{pointerEvents: 'none'}}>
                                <div className="service service__style--2">
                                    <div className="icon">
                                        {val.icon}
                                    </div>
                                    <div className="content">
                                        <h3 className="title">{val.title}</h3>
                                        <p>{val.description}</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                    ))}
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceThree;
