import React, { Component } from "react";
import {Link} from "react-router-dom";

const PortfolioListContent = [
    {
        image: 'image-10',
        category: 'Property Owners',
        title: 'Seamless communication for holiday apartment hosts, powered by state-of-the-art AI.',
        link: '',
        enabled: true
    },
    {
        image: 'image-11',
        category: 'Dental Clinics',
        title: 'Intuitive, AI-powered appointment scheduling and more, for your dental practice.',
        link: '',
        enabled: false
    },
    {
        image: 'image-12',
        category: 'E-commerce',
        title: '24/7 instant customer support for e-commerce platforms, enabled by innovative AI technology.',
        link: '',
        enabled: false
    }
]

class PortfolioList extends Component{
    render(){
        const {column , styevariation } = this.props;
        const list = PortfolioListContent.slice(0 , this.props.item);
        return(
            <React.Fragment> 
                {list.map((value , index) => (
                    <div className={`${column}`} key={index}>
                        {value.enabled == true &&
                            <div className={`portfolio ${styevariation} e2ai-main`}>
                                <div className="thumbnail-inner">
                                    <div className={`thumbnail ${value.image}`}></div>
                                    <div className={`bg-blr-image ${value.image}`}></div>
                                </div>
                                <div className="content">
                                    <div className="inner">
                                        <p>{value.category}</p>
                                        <h4><a href="/property-owners" target="_blank">{value.title}</a></h4>
                                        <div className="portfolio-button text-center">
                                            <a className="rn-btn" href="/property-owners" target="_blank">Learn More</a>                                            
                                        </div>
                                    </div>
                                </div>
                            <Link className="link-overlay" to=""></Link>
                            </div>
                        }
                        {value.enabled == false &&
                            <div className={`portfolio ${styevariation}`} style={{pointerEvents: "none", opacity:"0.4"}}>
                                <div className="thumbnail-inner e2ai-main">
                                    <div className={`thumbnail ${value.image}`}></div>
                                    <div className={`bg-blr-image ${value.image}`}></div>
                                </div>
                                <div className="content">
                                    <div className="inner">
                                        <p>{value.category}</p>
                                        <h4><a href="/">{value.title}</a></h4>
                                        <div className="portfolio-button text-center">                                            
                                            <a className="rn-btn" href="" aria-disabled="true" disabled>Coming Soon</a>
                                        </div>
                                    </div>
                                </div>
                            {/* <Link className="link-overlay" to=""></Link> */}
                            </div>
                        }
                        
                    </div>
                ))}
               
            </React.Fragment>
        )
    }
}
export default PortfolioList;